<template>
  <!--  icon : members-->
  <mcma-card title="liste des membres" icon="noIcon" class="mcma-card-members-list">
    <section>
      <a class="ninja" ref="downloadLink" />
    </section>
    <template #describe v-if="isAdminOrMore || isAdvancedCoordinator">
      <p>
        Vous pouvez vous connecter à l'espace d'un membre en cliquant sur l'icone
        <img class="imgInText" src="@/assets/images/home.svg" alt="mcma-home" />
        d'un membre.
      </p>
      <p v-if="isAdminOrMore || isAdvancedCoordinator">
        Vous pouvez envoyer en masse des e-mails en sélectionnant plusieurs membres et en cliquant par la suite sur un
        des boutons ci-dessous.
      </p>
      <p v-if="isAdminOrMore || isAdvancedCoordinator">
        Mais vous pouvez aussi en envoyer unitairement via les icones correspondant d'un membre.
      </p>

      <div class="actions-buttons" v-if="isAdminOrMore || isAdvancedCoordinator">
        <mcma-button name="e-mail de masse" :uppercase="true" class="mr24" leftImage="send_white" :isDropdown="true">
          <a-menu-item
            key="1"
            @click="sendMail('BDD_WELCOME', selectedMembers)"
            :disabled="selectedMembers.length === 0"
          >
            <mcma-icon
              type="paper-plane"
              color="blue"
              :faIcon="true"
              name="Bienvenue"
              :disabled="selectedMembers.length === 0"
            />
          </a-menu-item>
          <a-menu-item key="2" @click="sendMail('BDD_RAISE', selectedMembers)" :disabled="selectedMembers.length === 0">
            <mcma-icon
              type="paper-plane"
              color="yellow"
              :faIcon="true"
              name="Relance"
              :disabled="selectedMembers.length === 0"
            />
          </a-menu-item>
          <a-menu-item
            key="3"
            @click="sendMailWithExportMembre(selectedMembers)"
            :disabled="selectedMembers.length === 0"
          >
            <mcma-icon
              type="paper-plane"
              color="green"
              :faIcon="true"
              name="Remerciement"
              :disabled="selectedMembers.length === 0"
            />
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="4" @click="notPreserved(selectedMembers, false)" :disabled="selectedMembers.length === 0">
            <mcma-icon
              type="user-shield"
              color="red"
              :faIcon="true"
              name="Non Concerné"
              :disabled="selectedMembers.length === 0"
            />
          </a-menu-item>
          <a-menu-item key="5" @click="preserved(selectedMembers, false)" :disabled="selectedMembers.length === 0">
            <mcma-icon
              type="backward"
              color="purple"
              :faIcon="true"
              name="Remettre dans le circuit"
              :disabled="selectedMembers.length === 0"
            />
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="6" @click="goTo('emails-tracker')">
            <mcma-icon type="mail-bulk" color="red" :faIcon="true" name="Suivie des e-mail" />
          </a-menu-item>
        </mcma-button>
        <mcma-button
          name="périmètre d'opération"
          :uppercase="true"
          class="mr24"
          leftImage="edit_white"
          :isDropdown="true"
        >
          <a-menu-item key="1" @click="goTo('users-manager')">
            <mcma-icon type="user-friends" color="blue" :faIcon="true" name="Gestion des utilisateurs" />
          </a-menu-item>
          <a-menu-item key="2" @click="goTo('members-manager')" :disabled="loading">
            <mcma-icon type="users" color="green" :faIcon="true" name="Gestion des membres" />
          </a-menu-item>
          <a-menu-item key="3" v-if="isAdminOrMore && getFlow.includes('DOWNLOAD')" @click="goTo('documents-manager')">
            <mcma-icon type="folder-open" color="red" :faIcon="true" name="Gestion des pièces" />
          </a-menu-item>
        </mcma-button>

        <mcma-button name="actions d'export" :uppercase="true" class="mr24" leftImage="export_white" :isDropdown="true">
          <a-menu-item key="0">
            <mcma-export-generic-mage pipeline="wp_export_operation_engie" title="Exporter l'opération" :fileTitle="'Export Opération ' + operation.name"></mcma-export-generic-mage>
          </a-menu-item>
          <a-menu-item key="0-bis">
            <mcma-export-generic-mage pipeline="wp_export_operation_si_engie" title="Exporter SI" :fileTitle="'Export SI Opération ' + operation.name"></mcma-export-generic-mage>
          </a-menu-item>
          <a-menu-item key="1" @click="downloadExportOperation">
            <mcma-icon type="file-export" color="purple" :faIcon="true" name="Exporter (déprécié)" />
          </a-menu-item>
          <a-menu-item key="4" @click="downloadExportSI">
            <mcma-icon type="file-export" color="purple" :faIcon="true" name="Exporter SI (déprécié)" />
          </a-menu-item>
          <a-menu-item key="2" @click="exportMembersStats">
            <mcma-icon type="file-export" color="primary" :faIcon="true" name="Exporter les statistiques des membres" />
          </a-menu-item>
          <a-menu-divider />
        </mcma-button>
      </div>
    </template>
    <a-tabs type="card" class="members-tabs" :class="{ 'show-members-selection': selectedMembers.length > 0 }">
      <a-tab-pane key="1" tab="Membres">
        <mcma-spin-box :spinning="loading">
          <members-list-table
            :membresStats="membresStats"
            @selectedMembersChanged="selectedMembersChanged"
            @sendMail="(args) => sendMail(args.type, [args.member])"
            @notPreserved="(args) => notPreserved([args.member], args.all)"
            @exportAgain="(args) => exportAgain([args.member], args.all)"
            @exportParcours="(args) => exportParcours([args.member])"
            @sendMailWithExportMembre="(args) => sendMailWithExportMembre([args.member])"
            @preserved="(args) => preserved([args.member], args.all)"
            ref="membersListTable"
          />
        </mcma-spin-box>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Statistiques" :forceRender="true">
        <mcma-spin-box :spinning="loading">
          <members-list-table-stats
            :membresStats="membresStats"
            @selectedMembersChanged="selectedMembersChanged"
            @sendMail="(args) => sendMail(args.type, [args.member])"
            @notPreserved="(args) => notPreserved([args.member], args.all)"
            @exportAgain="(args) => exportAgain([args.member], args.all)"
            @exportParcours="(args) => exportParcours([args.member])"
            @sendMailWithExportMembre="(args) => sendMailWithExportMembre([args.member])"
            @preserved="(args) => preserved([args.member], args.all)"
            ref="membersListTableStats"
          />
        </mcma-spin-box>
      </a-tab-pane>
    </a-tabs>
  </mcma-card>
</template>

<script>
import McmaExportGenericMage from "@/components/mcma/common/McmaExportGenericMage.vue"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard.vue"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import MembersListTable from "@/components/mcma/members/components/MembersListTable"
import MembersListTableStats from "@/components/mcma/members/components/MembersListTableStats"
import Axios from "@/util/Axios"
import Constants from "@/util/Constants"
import { accorization } from "@/util/Helpers"
import _ from "lodash"
import Materialize from "materialize-css"
// import moment from 'moment';
import { mapActions, mapGetters, mapState } from "vuex"
import XLSX from "xlsx"
import moment from "moment"

const DATE_FORMAT = "DD-MM-YYYY HH:mm:ss"

export default {
  name: "MembersList",
  props: {
    membresStats: Array,
    loading: Boolean,
  },
  components: {
    McmaExportGenericMage,
    McmaCard,
    McmaButton,
    McmaIcon,
    MembersListTable,
    MembersListTableStats,
    McmaSpinBox,
  },
  data() {
    return {
      selectedMembers: [],
    }
  },
  computed: {
    ...mapState(["user", "operation"]),
    ...mapGetters(["getFlow"]),
    isAdminOrMore() {
      return this.user.role === "SUPER_ADMIN" || this.user.role === "ADMIN"
    },
    isAdvancedCoordinator() {
      return this.user.role === "COORDINATEUR_AVANCE"
    },
    toDate() {
      return (timestamp) => {
        return timestamp ? moment(timestamp, "x").format(DATE_FORMAT) : "Inconnue"
      }
    },
    toTime() {
      return (timestamp) => {
        if (timestamp) {
          var seconds = Math.floor(timestamp)
          var minutes = Math.floor(seconds / 60)
          var hours = Math.floor(minutes / 60)
          var days = Math.floor(hours / 24)
          var secondsf = Math.floor(seconds - minutes * 60)
          var minutesf = Math.floor(minutes - hours * 60)
          var hoursf = Math.floor(hours - days * 24)
          return days + "j " + hoursf + ":" + minutesf + ":" + secondsf
        }
        return "Inconnue"
      }
    },
  },
  methods: {
    ...mapActions(["requestMembresStats", "requestMembreStats"]),
    selectedMembersChanged(data) {
      const { membresStatsList, selectedRowKeys } = data
      this.selectedMembers = membresStatsList
      this.$refs.membersListTable.onSelectChange(selectedRowKeys, true)
      this.$refs.membersListTableStats.onSelectChange(selectedRowKeys, true)
    },
    customFilter(column, search, value) {
      if (search) {
        if (column === "progress") {
          var num = _.toNumber(search.replace(",", "."))
          if (num !== "Nan" && num < value * 100) {
            return false
          }
        }
      }
      return true
    },
    sendMail(type, membresStats) {
      const that = this
      const typeSentence = Constants.MAIL[type].text || type
      const successMessage = (items) =>
        `${accorization(items, "email", true, "aucun email")} ${typeSentence} ${accorization(items, "envoyé")}`
      const errorMessage = "Erreur lors de l'envoi \npour au moins l'un des emails."
      console.log("sendMail : %O / %O / %O", type, typeSentence, membresStats)
      if (type === Constants.MAIL.WELCOME_ADMIN.value) {
        const mails = ""
        const sentence = `Voulez-vous vraiment \nenvoyer ${accorization(
          membresStats.length,
          "email",
          true,
          "aucun email"
        )}' ${typeSentence} pour cette opération ?`
        this.$confirm({
          title: `Envoi d'email ${typeSentence}`,
          content: sentence,
          okText: "Oui",
          okType: "success",
          cancelText: "Non",
          onOk() {
            Axios("post", "/mail/membre?idOperation=" + that.operation.id, {
              from: that.user,
              type: type.toUpperCase(),
              to: mails,
            })
              .then((response) => {
                that.$notification.success({
                  message: `${successMessage(mails)}`,
                  description: `${successMessage(mails)} avec succés.`,
                })
              })
              .catch((error) => {
                console.error(error)
                that.$notification.error({
                  message: "Erreur d'envoi d'email",
                  description: `${errorMessage}`,
                })
              })
          },
          onCancel() {
            console.log(
              `%c Cancel send email`,
              "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
            )
          },
        })
      } else {
        if (membresStats && membresStats.length) {
          const mails = _.flatMap(membresStats, (membre) => {
            return membre.userEmail ? membre.userEmail.split(",") : []
          })
          const sentence = `Voulez-vous vraiment \nenvoyer ${accorization(
            mails,
            "email",
            true,
            "aucun email"
          )} ${typeSentence} pour cette opération ?`
          this.$confirm({
            title: `Envoi d'email ${typeSentence}`,
            content: sentence,
            okText: "Oui",
            okType: "success",
            cancelText: "Non",
            onOk() {
              Axios("post", "/mail/membre?idOperation=" + that.operation.id, {
                from: that.user,
                type: type.toUpperCase(),
                to: mails,
              })
                .then((response) => {
                  that.$notification.success({
                    message: `${successMessage(mails)}`,
                    description: `${successMessage(mails)} avec succés.`,
                  })
                })
                .catch((error) => {
                  // console.log("response : %O", response);
                  console.log("Error :%O", error)
                  that.$notification.error({
                    message: "Erreur d'envoi d'email",
                    description: error.response.data,
                    duration: 0,
                  })
                })
            },
            onCancel() {
              console.log(
                `%c Cancel send email`,
                "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
              )
            },
          })
        }
      }
    },
    buildExportName() {
      var date = new Date()
      var fileName =
        "Export_" +
        this.operation.name +
        "_" +
        date.getFullYear() +
        date.getMonth() +
        date.getDay() +
        "_" +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds() +
        ".xlsx"
      return fileName.replace(" ", "_")
    },
    downloadExportOperation() {
      console.log("> downloadExportOperation@Membres")
      if (this.operation) {
        var fileName = this.buildExportName()
        Axios(
          "get",
          "zdp/exportOperation?operation=" +
            this.operation.id +
            "&fileName=" +
            fileName +
            "&userEMail=" +
            this.user.email,
          null,
          {
            responseType: "blob",
          }
        )
          .then((response) => {
            console.log("> response :  %O", response)
            const link = this.$refs.downloadLink
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            link.setAttribute("download", fileName)
            link.click()
            this.$notification.success({
              message: `Téléchargement démarré`,
              description: ` Le fichier d'export est en cours de téléchargement sur votre navigateur....Vous pourrez le consulter une fois le téléchargement démarré.`,
            })
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: `Erreur lors du téléchargement`,
              description: `Une erreur est survenue lors \ndu téléchargement de l'operation ${this.operation.name}.\n\nVeuillez ressayer ultérieurement.`,
            })
          })
      } else {
        this.$notification.error({
          message: `Erreur de téléchargement`,
          description: `Vous devez sélectionner une opération \npour pouvoir télécharger son contenu.`,
        })
      }
    },
    exportMembersStats() {
      const date = new Date()
      let fileName =
        "Export_Membre_Statistiques_" +
        this.operation.name +
        "_" +
        date.getFullYear() +
        date.getMonth() +
        date.getDay() +
        "_" +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds() +
        ".xlsx"
      fileName = fileName.replace(" ", "_")

      const membersStatsToExport = []
      this.membresStats.forEach((member) =>
        membersStatsToExport.push({
          Nom: member.nom,
          Statut: member.status ? Constants["STATUS"][member.status].text : member.status,
          "Nombre de sites": member.statSite ? member.statSite.size : 0,
          "Progression des sites": member.statSite ? member.statSite.progress : 0,
          "Nombre de périmètres de facturation": member.statGroupement ? member.statGroupement.size : 0,
          "Progression des périmètres de facturation": member.statGroupement ? member.statGroupement.progress : 0,
          "Nombre de documents à traiter": member.statPiece ? member.statPiece.nbPiece : 0,
          "Nombre de documents téléchargé": member.statPiece ? member.statPiece.nbPieceDownloaded : 0,
          "Nombre de documents déposé": member.statPiece ? member.statPiece.nbPieceUploaded : 0,
          "Nombre d'interlocuteurs": member.statInterlocuteur ? member.statInterlocuteur.size : 0,
          "Progression des interlocuteurs": member.statInterlocuteur ? member.statInterlocuteur.progress : 0,
          "Auteur validation parcours": member.auteurValidationParcours,
          "Progression globale du membre": member.progress * 100,
          "Actif il y a": this.toTime(member.actif),
          "Date de dernière connexion": this.toDate(member.lastLogin) ,
        })
      )
      const membersStats = XLSX.utils.json_to_sheet(membersStatsToExport)

      const wb = XLSX.utils.book_new()
      membersStats["!cols"] = [
        { wch: 57 },
        { wch: 15 },
        { wch: 15 },
        { wch: 18 },
        { wch: 32 },
        { wch: 36 },
        { wch: 27 },
        { wch: 29 },
        { wch: 26 },
        { wch: 21 },
        { wch: 26 },
      ]
      XLSX.utils.book_append_sheet(wb, membersStats, "Statistiques des membres")
      XLSX.writeFile(wb, fileName)
      this.$notification.success({
        message: `Téléchargement démarré`,
        description: ` Le fichier d'export est en cours de téléchargement sur votre navigateur....Vous pourrez le consulter une fois le téléchargement démarré.`,
      })
    },
    downloadExportSI() {
      console.log("> downloadExportOperationDedieSI@Membres")
      var fileName = this.buildExportName()
      Axios("get", "zdp/exportOperationSI?operation=" + this.operation.id + "&fileName=" + fileName, null, {
        responseType: "blob",
      })
        .then((response) => {
          console.log("> response :  %O", response)
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", fileName)
          link.click()
        })
        .catch((error) => console.error(error))
    },
    sendMailWithExportMembre(membres) {
      console.log("membres : %O", membres)
      if (membres && membres.length && membres.userEmail !== null) {
        const membresApproved = _.filter(membres, (membre) => membre.status === "APPROVED")
        if (membresApproved && membresApproved.length <= 0) {
          Materialize.toast("vous devez approuver le membre", 4000, "lideep-orange white-text'me")
        } else {
          if (
            confirm(
              "Voulez-vous envoyer un mail de remerciement avec l'export de parcours de " +
                membresApproved.length +
                " membres ?"
            )
          ) {
            Axios("post", "export/membres/users?idOperation=" + this.operation.id, membresApproved)
              .then((response) => {
                Materialize.toast(response.data, 4000, "lime")
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: "Erreur lors de l'envoi de email",
                  description: "erreur",
                })
              })
          }
        }
      } else {
        Materialize.toast(
          "vous devez avoir au moins un utilisateur pour ce membre",
          4000,
          "lideep-orange white-text'me"
        )
      }
      console.groupEnd()
    },
    exportParcours(membresStats) {
      membresStats.forEach((membre) => {
        const date = new Date()
        let fileName =
          "Export_Parcours_" +
          this.operation.name +
          "_" +
          membre.nom +
          "_" +
          date.getFullYear() +
          date.getMonth() +
          date.getDay() +
          "_" +
          date.getHours() +
          date.getMinutes() +
          date.getSeconds() +
          ".xlsx"
        fileName = fileName.replace(" ", "_")
        Axios("get", "zdp/membres/parcours?operationId=" + this.operation.id + "&membreId=" + membre.id, null, {
          responseType: "blob",
        }).then((response) => {
          console.log("> response :  %O", response)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement("a")
          fileLink.href = url
          fileLink.setAttribute("download", fileName)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
      })
    },
    exportAgain(membresStats, all) {
      // si all = "true" => mail sur la boite mail opération avec fichier d'export uniquement
      // si all = "false" => mail remerciement à destination des utilisateurs des membres sélectionnés + fichier d'export
      console.group("exportAgain")
      console.log("membresStats : %O", membresStats)
      console.log("all : %O", all)
      if (membresStats && membresStats.length) {
        const realMembres = _.filter(membresStats, (membre) => {
          return membre.status === "EXPORTED" || membre.status === "APPROVED"
        })
        const idMembres = "," + _.map(realMembres, "id").join(",") + ","
        if (realMembres.length === 0) {
          this.$notification.error({
            message: "Réexportation impossible",
            description: `Vous ne pouvez réexporter les données d'un membre qui n'as jamais eu d'export de donnée jusqu'à prèsent.`,
          })
        } else {
          const that = this
          this.$confirm({
            title: `Réexporter les données`,
            content: `Voulez-vous vraiment réexporter \nles données de ${accorization(
              realMembres.length,
              "membre",
              true,
              "aucun membre"
            )} ?`,
            okText: "Oui",
            okType: "success",
            cancelText: "Non",
            onOk() {
              Axios(
                "get",
                "export/admin?admin=" +
                  that.user.id +
                  "&idMembres=" +
                  idMembres +
                  "&idOperation=" +
                  that.operation.id +
                  "&all=" +
                  all
              )
                .then((response) => {
                  that.$notification.success({
                    message: `${accorization(realMembres.length, "membre exporté", true, "Aucun membre exporté")}`,
                    description: `${accorization(
                      realMembres.length,
                      "membre a été exporté avec succés",
                      true,
                      "Aucun membre n'a été exporté"
                    )}.`,
                  })
                })
                .catch((error) => {
                  console.error(error)
                  that.$notification.error({
                    message: "Erreur lors des exports",
                    description: `Une erreur est survenue lors \nde l'export de ${accorization(
                      realMembres.length,
                      "membre",
                      true,
                      "Aucun membre"
                    )}.\n\nVeuillez ressayer ultérieurement.`,
                  })
                })
            },
            onCancel() {
              console.log(
                `%c Cancel approved export`,
                "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
              )
            },
          })
        }
      }
    },
    notPreserved(membres, all) {
      console.group("notPreserved")
      console.log("membres : %O", membres)
      console.log("all : %O", all)
      const that = this
      if (membres && membres.length) {
        const realMembres = membres.filter((m) => m.status === "INITIALISED" || m.status === "IN_PROGRESS")
        that.$confirm({
          title: `Non Concerné`,
          content: `Voulez-vous vraiment indiquer \nque ${accorization(
            realMembres.length,
            `membre`,
            true,
            "aucun membre"
          )} ne sont pas conserné par l'opération ?`,
          okText: "Oui",
          okType: "success",
          cancelText: "Non",
          onOk() {
            const requests = []
            realMembres.forEach((membre) => {
              requests.push(
                Axios("patch", "api/membres/" + membre.id, {
                  approuved: false,
                  notPreserved: true,
                })
              )
            })
            Promise.all(requests).then(() => {
              that.$notification.success({
                message: `${accorization(realMembres.length, "membre non concerné", true, "Aucun membre modifié")}`,
                description: `${accorization(
                  realMembres.length,
                  "membre",
                  true,
                  "Aucun membre"
                )} ont été changé en statut non concerné.`,
              })
              that.requestMembresStats().then(() => {
                that.$refs.membersListTable.initialize()
                that.$refs.membersListTableStats.initialize()
              })
            })
          },
          onCancel() {
            console.log(
              `%c Cancel not-preserve`,
              "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
            )
          },
        })
      }
      console.groupEnd()
    },
    preserved(membres, all) {
      const that = this
      if (membres && membres.length) {
        const realMembres = membres.filter((m) => m.status === "NOT_PRESERVED")
        that.$confirm({
          title: `Concerné`,
          content: `Voulez-vous vraiment indiquer \nque ${accorization(
            realMembres.length,
            `membre`,
            true,
            "aucun membre"
          )} sont finalement de retour dans le circuit de l'opération ?`,
          okText: "Oui",
          okType: "success",
          cancelText: "Non",
          onOk() {
            const requests = []
            realMembres.forEach((membre) => {
              requests.push(
                Axios("patch", "api/membres/" + membre.id, {
                  approuved: false,
                  notPreserved: false,
                })
              )
            })
            Promise.all(requests).then(() => {
              that.$notification.success({
                message: `${accorization(realMembres.length, "membre concerné", true, "aucun membre modifié")}`,
                description: `${accorization(
                  realMembres.length,
                  "membre",
                  true,
                  "aucun membre"
                )} sont de retour dans le circuit.`,
              })
              that.requestMembresStats().then(() => {
                that.$refs.membersListTable.initialize()
                that.$refs.membersListTableStats.initialize()
              })
            })
          },
          onCancel() {
            console.log(
              `%c Cancel preserve`,
              "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
            )
          },
        })
      }
      console.groupEnd()
    },
    split(value) {
      if (value) {
        return value.split(",")
      }
      return []
    },
    goTo(routeName) {
      this.$router.push({
        name: routeName,
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-members-list {
  .imgInText {
    width: 18px;
    margin-top: -4px;
  }
  .actions-buttons {
    padding: 24px 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .card-body {
    padding-top: 0 !important;
  }
  .members-tabs {
    .ant-tabs-bar {
      margin: 0;
    }
    &.show-members-selection {
      .mcma-table {
        .header {
          height: 41px;
        }
      }
    }
    .mcma-table {
      .header {
        transition: height 0.2s ease;
        height: 0;
      }
    }
  }
}
</style>
